import React from "react";
import { InfoIcon } from "shared-components/ui/Icons";
import { GetTime, renderChips } from "shared-pages/latest-commission/utils";

const ViewSalesTable = ({
  orders,
  setCouponDetailsModal,
  supervisorOnly = false,
  supervisorEmail = "",
  showMySales = false,
  tableLayout = true,
  compactLayout = false,
}) => {
  // console.log("orders: ", orders);

  const renderTableTop = () => {
    return (
      <div
        className={`border-b border-b-slate-800 w-full col-span-10 text-left  grid grid-cols-10  `}
      >
        <th scope="col" className="px-4 py-4">
          No
        </th>
        <span className="px-4 py-3  col-span-2">Date of Order</span>
        <span className="px-4 py-3  col-span-2">Purchased By</span>
        <span className="px-4 py-3  col-span-1">Products</span>
        <span className="px-4 py-3 text-center  col-span-1">Commission</span>
        <span className="px-4 py-3  text-center col-span-1">Earned</span>
        <span className="px-4 py-3 text-center  col-span-1">
          UserPaidAmount
        </span>
        <span className="px-4 py-3 text-center col-span-1">Coupon</span>
      </div>
    );
  };
  return (
    <>
      {tableLayout ? (
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                No
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Date of Order
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Purchased User Email
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Products
              </th>
              {/* <th scope="col" className="px-4 py-4 text-left">
                My Supervisor
              </th> */}
              <th scope="col" className="px-4 py-4 text-center">
                At Commission %
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Total Amount Earned
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                User Paid Amount
              </th>
              {/* <th scope="col" className="px-4 py-4 text-left">
                My Coupon
              </th> */}

              <th scope="col" className="px-4 py-4 text-left">
                Coupon
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Coupon Details:
              </th>
            </tr>
          </thead>
          <tbody>
            {orders
              ?.filter((order) => {
                // Filter by personalCoupon if showMySales is true
                const mySalesFilter = showMySales
                  ? order?.personalCoupon
                  : true;

                // Filter by supervisorEmail if supervisorOnly is true
                const supervisorFilter = supervisorOnly
                  ? order?.supervisor === supervisorEmail
                  : true;

                // Return true only if both filters pass
                return mySalesFilter && supervisorFilter;
              })
              ?.map((order, index) => {
                return (
                  <tr className="border-b" key={order?._id}>
                    <td className="px-4 py-3">{index + 1}</td>
                    <td className="px-4 py-3 text-left text-xs">
                      {order?.createdAt
                        ? GetTime(order?.createdAt) +
                          " " +
                          new Date(order?.createdAt)?.toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td className="px-4 py-3 text-left">
                      {order?.purchasedUserEmail}
                    </td>
                    <td className="px-4 py-3  text-left text-sm">
                      {order?.PRODUCTS && order?.PRODUCTS?.length > 0
                        ? order?.PRODUCTS?.join(",")
                        : "N/A"}
                    </td>
                    {/* <td className="px-4 py-3 text-left">{order?.supervisor}</td> */}
                    <td className="px-4 py-3 text-center">
                      {order?.myCommissionPercentage}%
                    </td>
                    <td className="px-4 py-3 text-center">
                      {order?.totalAmountEarned}
                    </td>
                    <td className="px-4 py-3 text-center">
                      {order?.userPaidAmount || "N/A"}
                    </td>
                    {/* <td className="px-4 py-3 text-center">
                      {order?.personalCoupon ? "Yes" : "No"}
                    </td> */}
                    <td className="px-4 py-3 text-left">
                      {renderChips(order?.code)}
                    </td>
                    <td className="px-4 py-3 flex items-center justify-center">
                      <button
                        onClick={() => {
                          setCouponDetailsModal({
                            isOpen: true,
                            data: {
                              couponUser: order?.couponUser,
                              code: order?.code,
                              purchasedUserEmail: order?.purchasedUserEmail,
                              orderId: order?.orderId,
                            },
                          });
                        }}
                      >
                        <InfoIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <>
          {orders
            ?.filter((order) => {
              // Apply the filter only if showMySales is true
              return showMySales ? order?.personalCoupon : true;
            })
            ?.map((order, index) => {
              return (
                <>
                  <div
                    className="border-b hidden md:grid grid-cols-10 w-full text-xs "
                    key={order?._id}
                  >
                    {compactLayout ? (
                      <>
                        {index === 0 && renderTableTop()}
                        <span className="px-4 py-3 col-span-1">
                          {index + 1}
                        </span>
                        <span className="px-4 py-3 col-span-2 text-xs">
                          {order?.createdAt
                            ? GetTime(order?.createdAt) +
                              " " +
                              new Date(order?.createdAt)?.toLocaleDateString()
                            : "N/A"}
                        </span>
                        <span className="px-4 py-3 col-span-2">
                          {order?.purchasedUserEmail}
                        </span>
                        <span className="px-4 text-xs py-3 col-span-1">
                          {order?.PRODUCTS && order?.PRODUCTS?.length > 0
                            ? order?.PRODUCTS?.join(",")
                            : "N/A"}
                        </span>

                        <span className="px-4 py-3 text-center">
                          {order?.myCommissionPercentage}%
                        </span>
                        <span className="px-4 py-3 text-center">
                          {order?.totalAmountEarned}
                        </span>
                        {/* <span className="px-4 py-3 text-center">
                        {order?.personalCoupon ? "Yes" : "No"}
                      </span> */}
                        <td className="px-4 py-3 text-center">
                          {order?.userPaidAmount || "N/A"}
                        </td>
                        {/* <span className="px-4 py-3 text-left">
                        {new Date(order?.createdAt).toLocaleDateString()}
                      </span> */}
                        {/* <span className="px-4 py-3 text-left">
                        {renderChips(order?.myRole)}
                      </span> */}
                        <span className="px-4 py-3  flex items-center justify-center">
                          <button
                            className="flex items-center space-x-2"
                            onClick={() => {
                              setCouponDetailsModal({
                                isOpen: true,
                                data: {
                                  couponUser: order?.couponUser,
                                  code: order?.code,
                                  purchasedUserEmail: order?.purchasedUserEmail,
                                  orderId: order?.orderId,
                                },
                              });
                            }}
                          >
                            <InfoIcon />
                          </button>
                        </span>
                      </>
                    ) : (
                      <>
                        {/* <span className="px-4 py-3">{order?._id}</span> */}
                        <span className="px-4 py-3 text-left">{index + 1}</span>
                        <span className="px-4 py-3 text-left">
                          {order?.purchasedUserEmail}
                        </span>
                        <span className="px-4 py-3 text-left">
                          {/* {order?.supervisor} */}
                        </span>
                        <span className="px-4 py-3 text-center">
                          {order?.myCommissionPercentage}%
                        </span>
                        <span className="px-4 py-3 text-center">
                          {order?.totalAmountEarned}
                        </span>
                        {/* <span className="px-4 py-3 text-center">
                        {order?.personalCoupon ? "Yes" : "No"}
                      </span>
                       */}
                        <td className="px-4 py-3 text-center">
                          {order?.userPaidAmount || "N/A"}
                        </td>
                        <span className="px-4 py-3 text-left">
                          {renderChips(order?.code)}
                        </span>
                        <span className="px-4 py-3 flex items-center justify-center">
                          <button
                            onClick={() => {
                              setCouponDetailsModal({
                                isOpen: true,
                                data: {
                                  couponUser: order?.couponUser,
                                  code: order?.code,
                                  purchasedUserEmail: order?.purchasedUserEmail,
                                  orderId: order?.orderId,
                                },
                              });
                            }}
                          >
                            <InfoIcon />
                          </button>
                        </span>
                      </>
                    )}
                  </div>

                  {/* <Mobile View */}
                  <div className="grid  md:hidden  gap-4">
                    {orders.map((order, index) => (
                      <div
                        key={order?._id}
                        className="bg-white md:hidden shadow-md border border-gray-200 rounded-lg p-4 mb-4 md:justify-between md:items-center"
                      >
                        <div className="flex md:flex-col md:w-1/5">
                          <span className="font-semibold">Order No:</span>{" "}
                          {index + 1}
                        </div>
                        <div className="flex flex-col md:w-1/5 mt-2 md:mt-0">
                          <span className="font-semibold">Date of Order:</span>
                          <span className="text-gray-700">
                            {order?.createdAt
                              ? new Date(order?.createdAt).toLocaleString()
                              : "N/A"}
                          </span>
                        </div>
                        <div className="flex flex-col md:w-1/5 mt-2 md:mt-0">
                          <span className="font-semibold">Purchased By:</span>
                          <span className="text-gray-700">
                            {order?.purchasedUserEmail}
                          </span>
                        </div>
                        <div className="flex flex-col md:w-1/5 mt-2 md:mt-0">
                          <span className="font-semibold">Products:</span>
                          <span className="text-gray-700">
                            {order?.PRODUCTS?.join(", ") || "N/A"}
                          </span>
                        </div>
                        <div className="flex flex-col md:w-1/5 mt-2 md:mt-0">
                          <span className="font-semibold">Commission:</span>
                          <span className="text-gray-700">
                            {order?.myCommissionPercentage}%
                          </span>
                        </div>
                        <div className="flex flex-col md:w-1/5 mt-2 md:mt-0">
                          <span className="font-semibold">Amount Earned:</span>
                          <span className="text-gray-700">
                            {order?.totalAmountEarned}
                          </span>
                        </div>
                        <div className="flex flex-col md:w-1/5 mt-2 md:mt-0">
                          <span className="font-semibold">
                            User Paid Amount:
                          </span>
                          <span className="text-gray-700">
                            {order?.userPaidAmount || "N/A"}
                          </span>
                        </div>
                        <div className="flex flex-col md:w-1/5 mt-2 md:mt-0">
                          <span className="font-semibold">Coupon:</span>
                          <button
                            className="text-blue-500 underline flex items-center mt-2"
                            onClick={() => {
                              setCouponDetailsModal({
                                isOpen: true,
                                data: {
                                  couponUser: order?.couponUser,
                                  code: order?.code,
                                  purchasedUserEmail: order?.purchasedUserEmail,
                                  orderId: order?.orderId,
                                },
                              });
                            }}
                          >
                            View Details
                            <InfoIcon size="w-5 h-5 ml-1" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              );
            })}
        </>
      )}
    </>
  );
};

export default ViewSalesTable;
