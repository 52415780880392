import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DraggableQuestion from './draggable-question'
import { EditIcon } from 'shared-components/ui/Icons'
import CustomBadges from 'shared-components/ui/CustomBadges'
import { Switcher } from 'shared-components/ui/CustomForm'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { DeckAPI } from '(apis)/(shared-apis)/academics/decks-api'

const SAQsPublishedTable = ({ questions, setQuestions, deckInformation, getQuestions, deckName ,pageView}) => {

  const navigate = useNavigate();
  const handlePublishedChange = async (questionId) => {
    const response = await DeckAPI("publishUnpublishedSAQ", "", { deckId: deckInformation._id, questionId });
    if (response.data.success) {
      toast.success("Question unpublished Successfully");
      getQuestions();
    } else {
      toast.error("Error while unpublishing question");
    }
  };


  return (
    <div className="mx-auto p-12 pt-0">
      <div className="overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold">
        <div className="overflow-x-auto">
          <table className="w-full mb-20 text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-4 text-left">
                  SR#
                </th>
                <th scope="col" className="px-4 py-4">
                  Question Id
                </th>
                <th scope="col" className="px-4 py-4">
                  Question Text
                </th>
                <th scope="col" className="px-4 py-4">
                  Tags
                </th>
                <th scope="col" className="px-4 py-4 text-center">
                  Published
                </th>
                <th scope="col" className="px-4 py-4 text-center">
                  Preview
                </th>
                <th scope="col" className="px-4 py-4 text-center">
                  Edit
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-gray-800">
              {questions.map((question, index) => (
              <tr             
                className="bg-white divide-y divide-gray-200 text-gray-800"
              >
                <td className="px-4 py-4">{index + 1}</td>
                <td className="px-4 py-4">{question._id}</td>
                <td
                  className="px-4 pt-5"
                  dangerouslySetInnerHTML={{
                    __html:
                      question.QuestionText?.length > 60
                        ? question.QuestionText.substring(0, 60) + "..."
                        : question.QuestionText,
                  }}
                ></td>
                <td className="px-4 py-4">
                  <div className="flex flex-wrap gap-1">
                    <CustomBadges label={question.meta.subject} showIcon={false} />
                    <CustomBadges label={question.meta.topic} showIcon={false} type="light" />
                    {question.meta.subTopics && question.meta.subTopics?.length > 0 && question.meta.subTopics.map((subTopic: string, index: number) => (
                      <CustomBadges key={index} label={subTopic} showIcon={false} type="info" />
                    ))}
                  </div>
                </td>
                {/*radio button*/}
                <td className="px-4 py-3 text-center">
                  <label className="flex items-center justify-center cursor-pointer">
                    <Switcher
                      isActive={undefined}
                      for={question._id}
                      onChange={() => {
                        handlePublishedChange(question._id);
                      }}
                      togglevalue={pageView === "SAQs" ? !question.published : question.published}
                      size="small"
                    />
                  </label>
                </td>
                <td
                  className="px-6 py-5 text-center"
                  onClick={() => {
                    window.open(
                      `https://premed.pk/deckattempt/tutormode/${deckName}?question=${index + 1
                      }`,
                      "_blank"
                    );
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500 hover:text-rose-500 cursor-pointer mt-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                    />
                  </svg>
                </td>
                <td className="px-4 py-4 text-center">
                  <div className="flex items-center justify-center" onClick={() => { window.open(`/editQuestion/${question._id}`, "_blank"); }}>
                    <EditIcon color="text-gray-500" size="w-6 h-6" />
                  </div>
                </td>

              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    )
  }

export default SAQsPublishedTable