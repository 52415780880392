import { Navigate, useNavigate } from 'react-router-dom';
import useGetData from 'shared-components/custom-hook/get-data';
import GlobalTable from 'shared-components/table/GlobalTable';
import CustomBadges from 'shared-components/ui/CustomBadges';
import { ManageIcon } from 'shared-components/ui/Icons';

type Report = {
  questionId: string;
  pendingCount: number;
  rejectedCount: number;
  resolvedCount: number;
};

type ReportsResponse = {
  reports: Report[];
};

const columns = [
  {
    title: 'Question ID',
    dataIndex: 'questionId',
    key: 'questionId',
  },
  {
    title: 'Pending Count',
    dataIndex: 'pendingCount',
    key: 'pendingCount',
    render: (pendingCount)=>{
      return <CustomBadges label={pendingCount} type='warning'/>
    }
  },
  {
    title: 'Rejected Count',
    dataIndex: 'rejectedCount',
    key: 'rejectedCount',
    render: (rejectedCount)=>{
      return <CustomBadges label={rejectedCount} type='error'/>
    }
  },
  {
    title: 'Resolved Count',
    dataIndex: 'resolvedCount',
    key: 'resolvedCount',
    render: (resolvedCount)=>{
      return <CustomBadges label={resolvedCount} type='success'/>
    }
  },
  {
    title: 'Manage',
    dataIndex: 'manage',
    key: 'manage',
    render: (manage, record)=>{
      return <div className='flex gap-2' onClick={()=>{
        return <Navigate to={`/questionDetails/${record.questionId}`} />
      }}> 
        <ManageIcon size='w-5 h-5' onClick={()=>{
          return <Navigate to={`/questionDetails/${record.questionId}`} />
        }}/>
      </div>
    }
  }
];

const AuditQuestionsReports = () => {
  const navigate = useNavigate();
  const { data, loading } = useGetData<ReportsResponse>("getAuditQuestionsReport");

  const reportsData = data?.reports || [];
  return (
    <GlobalTable 
      loading={loading} 
      data={reportsData} 
      columns={columns.map(column => {
        if (column.key === 'manage') {
          return {
            ...column,
            render: (manage, record) => {
              return (
                <div className='flex gap-2' onClick={() => navigate(`/questionDetails/${record.questionId}`)}> 
                  <ManageIcon size='w-5 h-5' onClick={() => navigate(`/questionDetails/${record.questionId}`)}/>
                </div>
              );
            }
          };
        }
        return column; // Return unchanged columns
      })} 
    />
  );
};

export default AuditQuestionsReports;
