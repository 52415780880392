import { useEffect, useState } from "react";
import {
  ExternalLinkIcon,
  SearchIcon,
} from "../../../shared-components/ui/Icons";
import { ButtonOutlined } from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import {
  formatNumberWithCommas,
  renderChips,
  renderStatusChips,
} from "../utils";
// import { Input, Label } from "../../../shared-components/ui/CustomForm";
// import { Image, Upload } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
// import { UserContext } from "../../../state-management/user-context";
// import toast, { Toaster } from "react-hot-toast";
import { ReimbursementModal } from "./components/reimbursement-modal";

const ReimbursementRequests = () => {
  const [reimbursements, setReimbursements] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectStatus, setSelectStatus] = useState("All");
  const [selectRole, setSelectRole] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    fetchReimbursements();
  }, [refreshData]);

  async function fetchReimbursements() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getAllReimbursements");
      // getAllReimbursementsApi();

      if (response?.data?.isSuccess) {
        const data = response.data.data;
      
        setReimbursements(data);
      }
    } catch (error) {
  
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectStatusChange = (selectedStatus) => {
    setSelectStatus(selectedStatus);
  };

  const filteredReimbursements = reimbursements.filter(
    (reimbursement) =>
      (selectStatus === "All" || reimbursement.status === selectStatus) &&
      (selectRole === "All" || selectRole === reimbursement?.role) &&
      (reimbursement?.email
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
        reimbursement?.name.includes(searchQuery))
  );

  const handleActionButton = (reimbursement) => {
    setModalData(reimbursement);
    setIsModalOpen(true);
  };

  return (
    <>
      {/* =========================== */}
      {/* =========================== */}
      {/*  ReimbursementForm Modal*/}
      <ReimbursementModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={modalData}
      />
      {/* =========================== */}
      {/* =========================== */}

      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-xl font-bold text-gray-500 mb-5">
            Reimbursement Requests
          </h2>
          <ButtonOutlined
            handleClick={() => setRefreshData((prev) => prev + 1)}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className="bg-white shadow-md sm:rounded-lg">
                <div className="flex items-end p-4 space-x-3">
                  <CustomDropDown
                    label="Status"
                    options={["All", "Pending", "Approved", "Rejected"]}
                    onSelect={(selectedStatus) =>
                      handleSelectStatusChange(selectedStatus.toString())
                    }
                    width="min-w-[12rem]"
                    value={selectStatus}
                  />
                  <CustomDropDown
                    label="Role"
                    options={[
                      "All",
                      "ASSOCIATE",
                      "HEAD",
                      "MANAGER",
                      "PROVINCIAL_DIRECTOR",
                      "NATIONAL_DIRECTOR",
                    ]}
                    onSelect={(selectedStatus) => setSelectRole(selectedStatus)}
                    width="min-w-[12rem]"
                    value={selectRole}
                  />
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <SearchIcon color="text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                      placeholder="Search by Order ID or Email"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                    />
                  </div>
                </div>
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-4">
                        User Email
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Status
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Role
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Date Requested
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Amount Requested
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredReimbursements &&
                      filteredReimbursements?.length > 0 &&
                      filteredReimbursements?.map((reimbursement) => (
                        <tr className="border-b" key={reimbursement?._id}>
                          <td className="px-4 py-3">{reimbursement?.name}</td>
                          <td className="px-4 py-3">{reimbursement?.email}</td>
                          <td className="px-4 py-3">
                            {renderStatusChips(reimbursement?.status)}
                          </td>
                          <td className="px-4 py-3">
                            {renderChips(reimbursement.role)}
                          </td>
                          <td className="px-4 py-3">
                            {new Date(
                              reimbursement?.dateRequested
                            )?.toLocaleDateString()}
                          </td>
                          <td className="px-4 py-3">
                            {formatNumberWithCommas(
                              reimbursement?.requestedAmount
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <button
                              className=""
                              onClick={() => {
                                handleActionButton(reimbursement);
                              }}
                            >
                              <ExternalLinkIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default ReimbursementRequests;
