import { ApiInterface } from "../api-interface/types";

const accountsApis = [
  {
    url: "/api/finance/getAllAccounts",
    method: "GET",
    name: "getAllAccounts",
  },
  {
    url: "/api/finance/addAccount",
    method: "POST",
    name: "addAccount",
  },
  {
    url: "/api/finance/updateAccount/:id",
    method: "PUT",
    name: "updateAccount",
  },
];
const miscellaneousPaymentsApis = [
  {
    url: "/api/finance/getAllMiscellaneousPayments",
    method: "GET",
    name: "getAllMiscellaneousPayments",
  },
  {
    url: "/api/finance/addMiscellaneousPayment",
    method: "POST",
    name: "addMiscellaneousPayment",
  },
  {
    url: "/api/finance/updateMiscellaneousPayment/:id",
    method: "PUT",
    name: "updateMiscellaneousPayment",
  },
];

export const financeApis: ApiInterface[] = [
  // service Provider
  {
    url: "/api/finance/getAllServiceProviders",
    method: "GET",
    name: "getAllServiceProviders",
  },
  {
    url: "/api/finance/addServiceProvider",
    method: "POST",
    name: "addServiceProvider",
  },
  {
    url: "/api/finance/updateServiceProvider/:id",
    method: "PUT",
    name: "updateServiceProvider",
  },
  // employees
  {
    url: "/api/finance/getAllEmployees",
    method: "GET",
    name: "getAllEmployees",
  },
  {
    url: "/api/finance/addEmployee",
    method: "POST",
    name: "addEmployee",
  },
  {
    url: "/api/finance/updateEmployee/:id",
    method: "PUT",
    name: "updateEmployee",
  },
  // Payments
  {
    url: "/api/finance/getAllPayments",
    method: "GET",
    name: "getAllPayments",
  },
  {
    url: "/api/finance/getAllPaymentsServiceProviderAndEmployee",
    method: "GET",
    name: "getAllPaymentsServiceProviderAndEmployee",
  },
  {
    url: "/api/finance/addPayment",
    method: "POST",
    name: "addPayment",
  },
  {
    url: "/api/finance/updatePayment/:id",
    method: "PUT",
    name: "updatePayment",
  },
  {
    url: "/api/finance/deletePayment/:id",
    method: "PUT",
    name: "deletePayment",
  },
  ...accountsApis,
  ...miscellaneousPaymentsApis,
];
