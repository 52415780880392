import React, { useContext, useState } from "react";
import CustomAntdModal from "../../shared-components/modal/CustomAntdModal";
import { Divider, Image, DatePicker, Input, Button } from "antd";
import dayjs from 'dayjs';
import toast, { Toaster } from "react-hot-toast";
import { backendUrl } from "constants/config";
import axios from "axios";
import { UserContext } from "state-management/user-context";

interface ViewScholarshipProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  clickedInfoData: any;
  fetchData: () => void;
}

const ViewScholarship: React.FC<ViewScholarshipProps> = ({
  isOpen,
  setIsOpen,
  clickedInfoData,
  fetchData
}) => {

  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Navigation must be used within a UserProvider");
  }
  const { user } = context;

  const [form, setForm] = useState({
    id: clickedInfoData._id,
    email: clickedInfoData.Email,
    skuIds: [],
    duration: "",
    customTags: "",
    approvedBy: user?.username
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (form.duration === "" || form.customTags === "") {
      toast.error("Please fill all the fields");
      return;
    }
    if (form.skuIds.length === 0) {
      toast.error("Please give access to at least one option");
      return;
    }
    setLoading(true);
    const newForm = { ...form };
    newForm.customTags = newForm.customTags.trim();
    newForm.approvedBy = user?.username;
    newForm.id = clickedInfoData._id;
    newForm.email = clickedInfoData.Email;


    const response = await axios.put(
      `${backendUrl}/AddApprove`,
      newForm
    );
    if (response.status === 200) {
      toast.success("Scholarship Approved");
      setIsOpen(false);
      fetchData();
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false);
  }

  
  const handleReject = async () => {
  //id, Email }
    setLoading(true);
    const newForm = {
      id: clickedInfoData._id,
      email: clickedInfoData.Email,
      approvedBy: user?.username
    }

    const response = await axios.put(
      `${backendUrl}/RejectPending`,
      newForm
    );
    if (response.status === 200) {
      toast.success("Scholarship Rejected");
      setIsOpen(false);
      fetchData();
    }
    setLoading(false);
  }
  
    return (
    <div>
      <CustomAntdModal
        isOpenModal={isOpen}
        setIsOpenModal={setIsOpen}
        title="Student Information"
        isFooter={false}
        okButtonProps={{ type: "primary" }}
      >
        <Toaster/>
        <div className="flex flex-col md:flex-row gap-8 mt-6 md:mt-0">
          <div className=" w-full  md:w-1/3 bg-gray-300 shadow-m rounded-xl px-8 pt-6 pb-8 mb-4 ">
            <div className="flex flex-col justify-center items-center  gap-8 ">
              <div>
                <div className="relative inline-flex items-center justify-center w-40 h-40 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                  <span className="font-extrabold text-6xl text-green-600  dark:text-gray-300">
                    {clickedInfoData?.Fname?.toUpperCase()?.charAt(0)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4 ml-4">
                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600"> Name</div>
                  <div className="text-black text-lg">
                    {clickedInfoData?.Fname}{" "}
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600">Phone</div>
                  <div className="text-black text-lg">
                    {clickedInfoData?.PhoneNumber}{" "}
                  </div>
                </div>
                <div className="flex flex-col gap-1 flex-wrap">
                  <div className=" text-gray-600">Email</div>
                  <div className="text-black text-xs font-semibold">
                    {clickedInfoData?.Email}{" "}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600">City</div>
                  <div className="text-black text-lg">
                    {clickedInfoData?.City}{" "}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600">Status</div>
                  <div className="text-black text-lg">
                    {clickedInfoData?.Status}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/3">
            <div>
              <div className="text-xl font-bold">Detials</div>
              <div>
                <Divider>Reasons for Applying</Divider>
                <p>{clickedInfoData?.Reason}</p>
              </div>
              <div>
                <Divider>Why do you love PreMed.pk</Divider>
                <p>{clickedInfoData?.WhyPremed}</p>
              </div>
              <div>
                <Divider>Applied For</Divider>
                {clickedInfoData?.AppliedFor?.map((option: string) => (
                  <div className="flex flex-row justify-between items-center">
                    {option.split(" -> ")[0]} {" -> "} {option.split(" -> ")[1]}
                    <span
                      className={`cursor-pointer ${form.skuIds.includes(option.split(" -> ")[2]) ? "text-green-600" : "text-red-600"}`}
                      onClick={() => {
                        const skuId = option.split(" -> ")[2];
                        if (form.skuIds.includes(skuId)) {
                          setForm({
                            ...form,
                            skuIds: form.skuIds.filter((id) => id !== skuId),
                          });
                        } else {
                          setForm({
                            ...form,
                            skuIds: [...form.skuIds, skuId],
                          });
                        }
                      }}
                    >
                      {form.skuIds.includes(option.split(" -> ")[2])
                        ? "Given Access"
                        : "Give Access"}
                    </span>
                  </div>
                ))}

                <div className="flex flex-row gap-4 mt-4">
                  <DatePicker
                    value={form.duration ? dayjs(form.duration) : null}
                    onChange={(date, dateString) => setForm({ ...form, duration: dateString as string })}
                    className="w-full"
                    placeholder="Select Duration"
                  />

                  <Input
                    value={form.customTags}
                    onChange={(e) => setForm({ ...form, customTags: e.target.value })}
                    className="w-full border-2 border-gray-300 rounded-md p-2"
                    placeholder="Example: Till end of 2025 Exam"
                  />
                </div>
                <div className="flex flex-row justify-end items-end mt-4">
                  <Button type="primary" onClick={handleSubmit} loading={loading}>Accept</Button>
                </div>
              </div>
              <div>
                <Divider>Supporting Document:</Divider>
                {clickedInfoData?.supportingDoc ? (
                  <Image
                    width={130}
                    height={130}
                    src={clickedInfoData?.supportingDoc}
                    alt="Profile"
                    className="w-16 h-16 rounded-full border border-red-400 cursor-pointer"
                  />
                ) : (
                  <p className="">Not Found!</p>
                )}
              </div>
              <div className="flex flex-row justify-end items-end mt-4">
                <Button type="primary" onClick={handleReject} disabled={loading}>Reject</Button>                
              </div>
            </div>
          </div>
        </div>
      </CustomAntdModal>
    </div>
  );
};

export default ViewScholarship;
