import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const CommissionAPIs: ApiInterface[] = [
  {
    url: "/api/v2/latest-commission/add",
    method: "POST",
    name: "addCommissionUser",
  },
  {
    url: "/api/v2/latest-commission/get-user-info",
    method: "POST",
    name: "getCommissionUserById",
  },
  {
    url: "/api/v2/latest-commission/compensation",
    method: "GET",
    name: "getUserCompensationUserById",
  },
  {
    url: "/api/v2/latest-commission",
    method: "GET",
    name: "getAllCommissionUsers",
  },
  {
    url: "/api/v2/latest-commission/update",
    method: "PUT",
    name: "updateCommissionUserById",
  },
  {
    url: "/api/v2/latest-commission/delete",
    method: "DELETE",
    name: "deleteCommissionUserById",
  },
  {
    url: "/api/v2/latest-commission/addOrderId",
    method: "PUT",
    name: "addOrderIdToCommissionUser",
  },
  {
    url: "/api/v2/latest-commission/hierarchy",
    method: "GET",
    name: "getHierarchy",
  },
  {
    url: "/api/v2/latest-commission/get-hierarchy",
    method: "GET",
    name: "getHierarchyById",
  },
  {
    url: "/api/v2/latest-commission/update-commission",
    method: "PUT",
    name: "updateCommissionPercentage",
  },
  {
    url: "/api/v2/latest-commission/reimbursements/get-all",
    method: "GET",
    name: "getAllReimbursements",
  },
  {
    url: "/api/v2/latest-commission/create-reimbursement",
    method: "POST",
    name: "createReimbursement",
  },
  {
    url: "/api/v2/latest-commission/reimbursement/promo-code/approve",
    method: "PUT",
    name: "createPromoCodeReimbursement",
  },
  {
    url: "/api/v2/latest-commission/reimbursement/update-status",
    method: "PUT",
    name: "updateReimbursementStatus",
  },
  {
    url: "/api/v2/latest-commission/reimbursement/approve",
    method: "PUT",
    name: "approveReimbursement",
  },
  {
    url: "/api/v2/latest-commission/transfer/move-entities-to-new-supervisor",
    method: "PUT",
    name: "moveEntitiesToNewSupervisor",
  },
  {
    url: "/api/v2/latest-commission/promote/head-to-manager/assign-new-head",
    method: "PUT",
    name: "promoteHeadandAssignNewHead",
  },
  {
    url: "/api/v2/latest-commission/promote/head-to-manager/promote-manager-along-with-associates",
    method: "PUT",
    name: "promoteManagerAlongWithAssociates",
  },
  {
    url: "/api/v2/latest-commission/promote/head-to-manager/promote-head-and-split-associates",
    method: "PUT",
    name: "promoteHeadAndSplitAssociates",
  },
  {
    url: "/api/v2/latest-commission/coupons/create",
    method: "POST",
    name: "addCoupon",
  },
  {
    url: "/api/v2/latest-commission/coupons/get-all",
    method: "GET",
    name: "getAllCoupons",
  },
  {
    url: "/api/v2/latest-commission/coupon",
    method: "GET",
    name: "getCouponById",
  },
  {
    url: "/api/v2/latest-commission/schemes/create-target",
    method: "POST",
    name: "createTarget",
  },
  {
    url: "/api/v2/latest-commission/schemes/get-user-referrals",
    method: "GET",
    name: "getUserReferralsById",
  },
  {
    url: "/api/v2/latest-commission/schemes/get-all",
    method: "GET",
    name: "getAllTargets",
  },
  {
    url: "/api/v2/latest-commission/schemes/get-all-by-type",
    method: "POST",
    name: "getAllTargetsbyType",
  },
  {
    url: "/api/v2/latest-commission/schemes/update-target",
    method: "PUT",
    name: "updateTarget",
  },
  {
    url: "/api/v2/latest-commission/schemes/assign-target",
    method: "POST",
    name: "assignTarget",
  },
  {
    url: "/api/v2/latest-commission/schemes/add-contacted-user",
    method: "POST",
    name: "addContactedUser",
  },
  {
    url: "/api/v2/latest-commission/logged-in-user-info",
    method: "GET",
    name: "getLoggedinCommissionUser",
  },
  {
    url: "/api/v2/latest-commission/schemes/get-user-singup-info-by-user-ids",
    method: "POST",
    name: "getUserSignupInfoByUserIds",
  },
];

export function CommissionAPI(
  name: string,
  params: string[] = [""],
  data: any = null
) {
  const api = CommissionAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");

  if (name === "approveReimbursement") {
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url,
  });
}
