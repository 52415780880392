import { useState } from "react";
import CustomBadges from "shared-components/ui/CustomBadges";
import { ChevronDown, ChevronUp } from "shared-components/ui/Icons";
import {
  getTotalAmountEarned,
  renderChips,
} from "shared-pages/latest-commission/utils";
import ViewSalesTable from "./view-sales-table";

const getUserIdField = (role) => {
  switch (role) {
    case "NATIONAL_DIRECTOR":
      return "provincialDirectorIds";
    case "PROVINCIAL_DIRECTOR":
    case "DIRECTOR":
      return "managerIds";
    case "MANAGER":
    case "HEAD":
      return "associateIds";
    default:
      return null;
  }
};

const CollapsibleSection = ({
  title,
  children,
  isDisabled,
  ManagerLevel = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  let themeStyle =
    title === "Sales"
      ? "hover:bg-emerald-100 bg-emerald-50 border-emerald-300"
      : title === "Signups"
      ? "hover:bg-amber-100 bg-amber-50 border-amber-300"
      : "hover:bg-cyan-100 bg-cyan-50 border-cyan-300";

  if (ManagerLevel && title === "Signups") return;

  return (
    <div className="my-2 md:my-3">
      <div
        onClick={() => {
          if (!isDisabled) setIsOpen(!isOpen);
        }}
        className={`cursor-pointer font-semibold text-gray-800 p-2 rounded-md flex justify-between items-center border-2 ${
          isDisabled
            ? "cursor-not-allowed bg-gray-50/70 text-gray-400"
            : themeStyle
        }`}
      >
        <span>{isDisabled ? "No sales available" : title}</span>
        {!isDisabled && (
          <button>
            {isOpen ? (
              <ChevronUp size="w-4 h-4 text-gray-600" />
            ) : (
              <ChevronDown size="w-4 h-4 text-gray-600" />
            )}
          </button>
        )}
      </div>
      {!isDisabled && isOpen && <div className="pl-1 py-1">{children}</div>}
    </div>
  );
};

const NestedOrders = ({
  users,
  showOrders,
  setCouponDetailsModal,
  level = 0,
}) => {
  return (
    <div className={`ml-${level * 2} md:ml-${level * 4}`}>
      {users &&
        users.map((user) => {
          const fieldName = user?.isPromoted
            ? getUserIdField(user?.promotedFrom)
            : getUserIdField(user?.role);

          return (
            <div key={user?._id} className="my-3 md:my-4">
              <div className="bg-white rounded-lg shadow-sm p-1 md:p-4 mb-2 border border-gray-100">
                {/* Mobile-first grid layout */}
                <div className="space-y-2 md:space-y-0 md:grid md:grid-cols-4 md:gap-4 md:items-center">
                  {/* Name - Full width on mobile */}
                  <div className="font-semibold">
                    <span>Name:</span> {user?.name}
                  </div>

                  {/* Email - Full width on mobile */}
                  <div className="break-all md:break-normal">
                    <span className="font-semibold">Email:</span> {user?.email}
                  </div>

                  {/* Commission - Inline on mobile */}
                  <div className="flex items-center space-x-2">
                    <span className="font-semibold">Commission:</span>
                    <CustomBadges
                      type="warning"
                      label={`${user?.commissionPercentage}%`}
                      showIcon={false}
                    />
                  </div>

                  {/* Amount - Inline on mobile */}
                  <div className="flex items-center space-x-2">
                    <span className="font-semibold">Amount Earned:</span>
                    <span>{getTotalAmountEarned(user).toFixed(2)}</span>
                  </div>
                </div>

                {showOrders && (
                  <>
                    <CollapsibleSection
                      title="Sales"
                      isDisabled={!user?.orders || user?.orders?.length <= 0}
                      ManagerLevel={level === 0}
                    >
                      <ViewSalesTable
                        orders={user?.orders}
                        setCouponDetailsModal={setCouponDetailsModal}
                        showMySales
                        tableLayout={false}
                        compactLayout={true}
                        supervisorOnly
                        supervisorEmail={user?.email}
                      />
                    </CollapsibleSection>
                  </>
                )}

                {user[fieldName] && user[fieldName]?.length > 0 && (
                  <CollapsibleSection title="My Team" isDisabled={false}>
                    <NestedOrders
                      users={user[fieldName]}
                      level={level + 1}
                      showOrders
                      setCouponDetailsModal={setCouponDetailsModal}
                    />
                  </CollapsibleSection>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default NestedOrders;

// import CustomBadges from "shared-components/ui/CustomBadges";
// import {
//   getTeamLength,
//   getTotalAmountEarned,
//   renderCellColor,
//   renderChips,
// } from "shared-pages/latest-commission/utils";
// import ViewSalesTable from "./view-sales-table";

// const getUserIdField = (role) => {
//   switch (role) {
//     case "NATIONAL_DIRECTOR":
//       return "provincialDirectorIds";
//     case "PROVINCIAL_DIRECTOR":
//       return "managerIds";
//     case "DIRECTOR":
//       return "managerIds";
//     case "MANAGER":
//       return "associateIds";
//     case "HEAD":
//       return "associateIds";
//     default:
//       return null;
//   }
// };

// const NestedOrders = ({
//   users,
//   showOrders,
//   setCouponDetailsModal,

//   level,
// }) => {
//   return (
//     <>
//       {users &&
//         users
//           // ?.slice((currentPage - 1) * PerPage, currentPage * PerPage)
//           ?.map((user) => {
//             //Let's suppose Head ko Promote kra Manager pr along with associates like manager directly, then ham dekhenge ke wo promote konse role se hoa he, is case me wo HEAD se promote hoa he Manager pr, then ab hm uske headIds ko access nhi krenge bulke uske associateIds ko access krenge cause wo manager to he but uske under me Head nhi he bulke wo direclty ab associates ko handle kr raha he
//             const fieldName = user?.isPromoted
//               ? getUserIdField(user?.promotedFrom)
//               : getUserIdField(user?.role);
//             // console.log("fieldName: ", fieldName);
//             return (
//               <>
//                 <table
//                   style={{
//                     width: 100 - level * 2 + "%",
//                     marginLeft: "auto",
//                   }}
//                   className="w-full  text-sm text-left text-gray-700"
//                 >
//                   <thead
//                     className={`text-xs text-gray-800 uppercase  ${renderCellColor(
//                       user?.role
//                     )}`}
//                   >
//                     <tr>
//                       <th scope="col" className="px-4 py-1">
//                         Name
//                       </th>
//                       <th scope="col" className="px-4 py-1.5 text-left">
//                         Email
//                       </th>
//                       <th scope="col" className="px-4 py-1.5 text-left">
//                         Role
//                       </th>
//                       <th scope="col" className="px-4 py-1.5 text-center">
//                         Report Info
//                       </th>
//                       {/* <th scope="col" className="px-4 py-1.5 text-left">
//                         Report to
//                       </th> */}
//                       <th scope="col" className="px-4 py-1.5 text-left">
//                         Team
//                       </th>

//                       <th scope="col" className="px-4 py-1.5 text-center">
//                         Commision%
//                       </th>
//                       <th scope="col" className="px-4 py-1.5 text-center">
//                         Amount Earned
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr
//                       className={`border-b text-xs`}
//                       style={{
//                         marginLeft: level * 8 + "px",
//                       }}
//                       key={user?._id}
//                     >
//                       <td className="px-4 py-3">{user?.name}</td>
//                       <td className="px-4 py-3">{user?.email}</td>
//                       <td className="px-4 py-3 text-left">
//                         {renderChips(user?.role)}
//                       </td>

//                       <td className="px-4 py-3">
//                         {user?.reportTo?.email}{" "}
//                         {user?.reportTo?.name
//                           ? "- " + user?.reportTo?.name
//                           : ""}
//                       </td>
//                       {/* <td className="px-4 py-3 text-left">
//                         {renderChips(user?.reportTo?.role)}
//                       </td> */}
//                       <td className="px-4 py-3 text-left">
//                         {getTeamLength(user, user?.role)}
//                       </td>

//                       <td className="px-4 py-3 text-center">
//                         <CustomBadges
//                           type="warning"
//                           label={user?.commissionPercentage}
//                           showIcon={false}
//                         />
//                       </td>
//                       <td className="px-4 py-3 text-center">
//                         {getTotalAmountEarned(user)}
//                       </td>
//                     </tr>

//                     {showOrders ? (
//                       <tr className="w-full ">
//                         <td colSpan={8} className="w-full ">
//                           <ViewSalesTable
//                             orders={user?.orders}
//                             setCouponDetailsModal={setCouponDetailsModal}
//                             showMySales
//                             tableLayout={false}
//                             compactLayout={true}
//                             supervisorOnly
//                             supervisorEmail={user?.email}
//                           />
//                         </td>
//                       </tr>
//                     ) : null}
//                   </tbody>
//                 </table>
//                 {user[fieldName] && user[fieldName]?.length > 0 && (
//                   <NestedOrders
//                     users={user[fieldName]}
//                     level={level + 1}
//                     showOrders
//                     setCouponDetailsModal={setCouponDetailsModal}
//                   />
//                 )}
//               </>
//             );
//           })}
//     </>
//   );
// };
// export default NestedOrders;

// =========================
